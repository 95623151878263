import axios from "axios";
import config from '../../config';
//const development = true;

//RUTA DEV
//const dev = "http://localhost:8080/api";

//RUTA PROD
//const prod = "https://contact.kuboti.com/api";

//var urlService = development ? dev : prod;
var urlService = "";
const dev = config.APP_DEV;
if(dev){
  urlService  = config.API_URL_DEV;
}else{
  urlService  = config.API_URL_PROD;
}




// Obtener el token JWT de donde lo hayas almacenado (en este caso, localStorage)
//var token = localStorage.getItem('token');

//const token = JSON.parse(localStorage.getItem('token'));

// Configurar el encabezado de autenticación JWT en Axios
//axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : null;

export default axios.create({
  baseURL: urlService,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Allow-Headers": "*",
  },
});
